@import url("../../styles/variables.css");

.navbar {
  background-color: var(--color-primary-white);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 135px;
  z-index: 9;
  position: sticky;
  top: 0;
  border-bottom: 2px solid var(--color-primary-green);
  box-shadow: 0px -1px 3px var(--color-primary-black);
}

.navbar-img {
  width: 120px;
}

.navbar-link-container {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
}

.navbar-link {
  font-size: var(--fontsize-small);
  text-decoration: none;
  color: var(--color-primary-green);
  font-weight: var(--fontweight-primary-bold);
}

.navbar-link:hover {
  color: var(--color-secondary-green);
}

.navbar-toggler {
  display: none;
  font-size: 24px;
  background: white;
  border: solid 2px var(--color-primary-green);
  border-radius: 8px;
  color: var(--color-primary-green);
  cursor: pointer;
}

.navbar-container {
  display: flex;
  align-items: center;
  width: 100%;
}

.icon-hamburger{
  display: none
}

@media (max-width: 768px) {
  .icon-hamburger {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-right: 26px;
  }

  .navbar {
    padding: 0;
    justify-content: start;
    padding-left: 20px;
  }

  .navbar-toggler {
    display: block;
  }

  .navbar-container {
    width: 120px;
  }


  .navbar-link-container {
    display: none;
    flex-direction: column;
    gap: 0;
    position: absolute;
    top: 60px;
    right: 0;
    background-color: #333;
    width: 100%;
  }

  .navbar-link-container.open {
    display: flex;
    margin-top: 45px;
    background-color: white;
    border-top: 2px var(--color-primary-green) solid;
  }

  .navbar-link {
    padding: 15px;
    text-align: center;
    border-bottom: 1px solid var(--color-primary-green);
  }
}
