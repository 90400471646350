@import url("../../styles/variables.css");

.menuicons-container {
  display: flex;
  background-color: var(--color-primary-white);
  justify-content: center;
  padding: var(--padding--space--web);
  flex-wrap: wrap;
  max-width: 1116px;
}
