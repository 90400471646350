@import url("../../styles/variables.css");

.banner-container {
  display: flex;
    justify-content: center;
    width: 100%;
    height: 450px;
}

.react-player {
  position: fixed;
  top: -25px;
  left: 0;
  opacity: 0.7;
}

.react-player > video {
  width: 100vw!important;
}

.banner-text-container {
  position: absolute; 
  color: var(--color-primary-white);
  z-index: 1;   
  width: 100%;
  display: flex;
  justify-content: center;
}

.about-banner-title{
  font-size: var(--fontsize-big);
  font-weight: var(--fontweight-primary-bold);
  color: var(--color-primary-white);
}

.about-banner-content {
  font-size: var(--fontsize-small);
  color: var(--color-primary-white);
}
  
@media (max-width: 1024px) {
  .react-player > video {
    width: auto!important;
  }
}


@media (max-width: 780px) {
  .react-player{
    top:0
  }

  .react-player > video {
    width: 700px!important;
  }

  .about-banner-container{
    height: 260px !important;
  }
  .about-banner-title {
    font-size: var(--fontsize-medium) !important;
  }

  .about-banner-content {
    font-size: var(--fontsize-small) !important;
  }
  
}

