@import url("../styles/variables.css");

.about-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
}

.about-banner-container {
  position: relative;
  width: 100%;
  height: 400px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  background-color: var(--color-black-trnasparent);
}

.about-banner-container img {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  height: auto;
  z-index: -1;
  opacity: 0.85;
}
.about-banner-text-container {
  display: flex;
  flex-direction: column;
  max-width: 1140px;
  justify-content: initial;
  height: 100%;
  margin: 0 var(--margin--space--medium);
  padding: 0 var(--margin--space--medium);
}
.about-banner-title {
  font-size: var(--fontsize-very-big);
  margin-bottom: var(--margin--space--short);
}

.about-banner-content {
  font-size: var(--fontsize-small);
}

.about-banner-title,
.about-banner-content {
  position: relative;
  z-index: 1;
  text-align: center;
}

.about-banner-content {
  font-size: 24px;
  font-weight: 700;
}

.about-content-container {
  display: flex;
  padding: var(--margin--space--medium);
  max-width: 1140px;
  gap: 80px;
  margin-top: 37px;
}

.about-content-title {
  font-size: var(--fontsize-big);
}

.about-content-text {
  font-size: 18px;
  margin-top: 20px;
  text-align: justify;
}

.about-content-img {
  border-radius: 20px;
  width: 400px;
  height: auto;
}

.about-container-img {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 480px) {
  .about-banner-title {
    font-size: var(--fontsize-big);
  }
  .about-banner-text-container {
    overflow-x: auto;
    scrollbar-width: none;
  }

  .about-banner-text-containe::-webkit-scrollbar {
    display: none; /* Ocultar scrollbar en Chrome, Safari y Opera */
  }
}

@media (max-width: 780px) {
  .about-container > .about-banner-container {
    height: 430px !important;
  }

  .about-banner-container img {
    height: 50vh;
  }

  .about-content-container {
    flex-direction: column;
    width: 90%;
  }
}
