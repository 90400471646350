@import url("../../styles/variables.css");

.contact-form {
    height: 100vh;
    display: flex;
    justify-content: start;
    padding: var(--padding--space--web);
    flex-direction: column;
    align-items: center;
    background-color: var(--color-primary-white);
    margin: 10px;
}

.form-content {
    width: 500px;
}

.form-content-about {
    width: 400px;
}

.contact-form-title {
    font-size: var(--fontsize-big);
    margin: 0;
}

.input-content {
    display: flex;
    flex-direction: column;
    margin: var(--padding--space--web) 0px;
}

.input-content-about {
    display: flex;
    flex-direction: column;
    margin: 2px 0px
}

.input-content-input {
    height: 38px;
    max-width: 500px;
    border-radius: 8px;
    border: 2px solid var(--color-primary-gray);
}

input:focus, textarea:focus {
    outline: none;
    border: solid 2px var(--color-primary-green);
}

.input-content-textarea {
    height: 50px;
    max-width: 500px;
    border-radius: 8px;
    border: 2px solid var(--color-primary-gray);
}

.input-content-textarea-about {
    height: 50px;
    max-width: 500px;
    border-radius: 8px;
    border: 2px solid var(--color-primary-gray);
}

.form-button {
    background-color: var(--color-primary-green);
    color: var(--color-primary-white);
    height: 38px;
    width: 120px;
    border: 0;
    border-radius: 8px;
    font-size: var(--fontsize-small);
    font-weight: var(--fontweight-primary-bold);
}

.error-message-form {
    font-size: var(--fontsize-tiny);
    color: var(--color-error);
    text-align: end;
    height: 2px;
}



@media (max-width: 480px) {

    .input-content-input {
        max-width: 300px;
    }
    
    .input-content-textarea {

        max-width: 300px;
    }

    .form-content {
        width: 300px;
    }
}

