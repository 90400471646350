:root {
    --color-primary-green: #6b9d23;
    --color-secondary-green: #97bf14;
    --color-tertiary-green: #e4e665;

    --color-primary-gray: #adadb0;

    --color-primary-white: #ffffff;

    --color-primary-black: #222222;

    --color-whatsapp-primary: #25d366; 

    --color-error:#ff3333;

    --color-black-trnasparent:#000000b9;


    --fontweight-primary-bold: 700;

    --fontfamily-primary: Arial, Helvetica, sans-serif;

    --padding--space--web: 26px;
    --padding--space--mobile: 13px;

    --margin--space--medium: 26px;
    --margin--space--short: 13px;

    --fontsize-very-big: 55px;
    --fontsize-big: 40px;
    --fontsize-tittle-mobile:30px;
    --fontsize-medium: 24px;
    --fontsize-small: 18px;
    --fontsize-very-small: 14px;
    --fontsize-tiny: 10px;


    --breakpoint-mobile-small: 480px;
    --breakpoint-mobile-large: 767px;
    --breakpoint-tablet: 1024px;
    --breakpoint-desktop-small: 1200px;
    --breakpoint-desktop-large: 1201px;

}