@import url("../../styles/variables.css");

.button-go-home {
  color: var(--color-primary-black);
  border-radius: 8px;
  font-size: var(--fontsize-small);
  font-weight: var(--fontweight-primary-bold);
  text-align: end;
}

.button-go-home:hover {
  opacity: 0.7;
  color: var(--color-secondary-green);
}
