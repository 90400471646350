.slider-img {
  width: auto;
  height: 90px;
}

.slick-track {
  display: flex;
  align-items: center;
  margin-bottom: 80px;
}

.slider-container-img {
  display: flex !important;
  justify-content: center;
}

.slider-container {
  padding: 26px 0px;
  width: 90%;
}


.slick-arrow::before {
  color: var(--color-primary-gray);
  font-size: 50px;
  position: absolute;
  left: -16px;
  top: -60px
}

@media (max-width: 780px) {

  .slick-arrow {
    display: none !important;
  }
}


@media (max-width: 480px) {
  .slider-img {
    width: auto;
    height: 60px;
  }

  .slider-container {
    width: 80%;
  }

  .slick-dots {
    display: none !important
  }
}
