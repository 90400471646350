@import url("../../../styles/variables.css");

.whatsappButton-container {
    align-items: center;
    background: var(--color-whatsapp-primary);
    border-radius: 100%;
    bottom: 40px;
    box-shadow: 1px 1px 10px var(--color-primary-gray);
    display: flex;
    font-size: 55px;
    height: 65px;
    justify-content: center;
    position: fixed;
    right: 40px;
    width: 65px;
    z-index: 999;
    }
    
    .fa-whatsapp {
    color: white;
    }


    @media (max-width: 480px) {
        .whatsappButton-container {
            bottom: 25px;
            font-size: 40px;
            height: 50px;
            right: 25px;
            width: 50px;
        }
    }