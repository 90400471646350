@import url("../../styles/variables.css");

.footer-container {
  background-color: var(--color-primary-green);
  display: flex;
  justify-content: space-around;
  padding: var(--padding--space--web) 0px;
  z-index: 9;
  position: relative;
  border-top: 2px solid var(--color-tertiary-green);
}

.footer-container > .footer-container-content > .navbar-link-container {
  flex-direction: column;
}

.footer-content-title {
  font-size: 18px;
}

.footer-container-contact {
  display: flex;
  flex-direction: column;
}

.footer-contet-text {
  font-size: var(--fontsize-small);
}

.footer-contact-container {
  color: var(--color-primary-white);
  display: flex;
  gap: 13px;
  margin-bottom: 13px;
  text-decoration: none;
}

.footer-content-title {
  color: var(--color-primary-white);
  margin: 0;
  margin-bottom: 13px;
}

.footer-container-content .navbar-link {
  margin-bottom: 13px;
  color: var(--color-primary-white);
}

.footer-contact-container:hover,
.footer-container-content .navbar-link:hover {
  color: var(--color-tertiary-green);
}

.footer-container-logo .navbar-img {
  width: 140px;
  background: var(--color-primary-white);
  border-radius: 10px;
}

.footer-container-logo {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.logo-title {
  color: var(--color-primary-white);
  margin-top: 20px;
}

@media (max-width: 780px) {
  .footer-container-logo .navbar-img {
    width: 80px;
  }
}

@media (max-width: 480px) {
  .footer-container-logo{
    display: none;
  }

  .footer-contet-text {
    font-size: var(--fontsize-very-small);
  }
}
