@import url("../styles/variables.css");

.home-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.home-main-container {
  background: var(--color-primary-white);
  z-index: 1;
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
}

.aboutus-container {
  background-color: var(--color-primary-gray);
  padding: var(--padding--space--web);
  display: flex;
}

.aboutus-title {
  width: 40%;
  display: flex;
  align-items: center;
  padding-left: var(--padding--space--web);
  font-size: var(--fontsize-big);
  margin-left: var(--padding--space--web);
  justify-content: flex-start;
  color: var(--color-primary-white);
}

.aboutus-text {
  width: 60%;
  padding-right: var(--padding--space--web);
  font-size: var(--fontsize-medium);
  color: var(--color-primary-white);
  font-weight: 600;
  text-align: justify;
}

.our-clients-container {
  width: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.our-clients-title {
  text-align: center;
  font-size: var(--fontsize-big);
  color: var(--color-primary-green);
}


@media (max-width: 780px) {
  .aboutus-container {
    flex-direction: column;
  }
  .aboutus-text {
    width: 100%;
    padding: 0;
    font-size: var(--fontsize-small);
  }
  .aboutus-title {
    width: 100%;
    padding: 0;
    display: flex;
    justify-content: center;
    font-size: var(--fontsize-medium);
  }

  .our-clients-title {
    font-size: var(--fontsize-medium);
  }
}
