@import url("../../styles/variables.css");

.cardIcon-container {
  background-color: var(--color-primary-white);
  height: 230px;
  width: 230px;
  margin: var(--margin--space--short);
  border-radius: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 26px;
  border: 1px solid var(--color-primary-green);
  padding: var(--padding--space--mobile);
  box-shadow: 1px 0px 10px -4px var(--color-primary-black);
  transition: all 0.3s ease;
}

.cardIcon-container:hover {
  transform: translateY(-5px);
  opacity: 0.7;
}

.cardIcon-icon {
  font-size: 50px;
  color: var(--color-primary-white);

  background: var(--color-primary-green);
  border-radius: 100%;
  padding: 20px;
}

.cardIcon-text {
  font-size: var(--fontsize-medium);
  font-weight: var(--fontweight-primary-bold);
  color: var(--color-primary-green);
  text-align: center;
}

@media (max-width: 780px) {
  .cardIcon-container {
    border: none;
    box-shadow: none;
    height: 150px;
    width: 100px;
    justify-content: initial;
  }
  .cardIcon-icon {
    font-size: 30px;
  }

  .cardIcon-text {
    font-size: 18px;
  }
}
